<template>
  <div class="container" v-title :data-title="$t('i18n.renewManage')">
    <div id="outer-title">{{ $t("i18n.renewManage") }}</div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import jlTable from "../../components/table";
export default {
  name: "RenewUpkeep",
  components: {
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const router = useRouter();
    const state = reactive({
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "number",
          label: "maintenanceContractNo",
          align: "center",
          search: true,
          type: "input",
          formatter: (row) => {
            return `<a>${row.number}</a>`;
          },
          method: (row) => {
            let page = row.upkeepType == 10 ? "Viewupdate" : "Viewcou";
            router.push({
              path: "/upkeepContract/upkeepContract" + page,
              query: { id: row.id },
            });
          },
        },
        {
          prop: "ownerShortname",
          label: "ownerNickname",
          align: "left",
          width: 260,
          search: true,
          type: "input",
        },
        {
          prop: "remainDays",
          label: "contractRemainingDays",
          align: "center",
        },
        {
          prop: "renewS",
          label: "renewalStatus",
          align: "center",
          search: true,
          type: "select",
          props: { label: "locale", value: "id" },
          data: [
            { locale: "stateless", id: "0" },
            { locale: "INHAND", id: "5" },
          ],
        },
        {
          prop: "status",
          label: "contractStatus",
          align: "center",
        },
        {
          prop: "remainElevator",
          label: "numberOfElevator",
          align: "center",
        },
        {
          prop: "",
          label: "allRenew",
          align: "center",
          formatter: (row) => {
            return row.renewStatus == 0 ? `<a>${t("i18n.allRenew")}</a>` : "-";
          },
          method: (row) => {
            enterAddPage(row.id, 1, row.number);
          },
        },
        {
          prop: "",
          label: "partialRenew",
          align: "center",
          formatter: () => {
            return `<a>${t("i18n.partialRenew")}</a>`;
          },
          method: (row) => {
            enterAddPage(row.id, 2, row.number);
          },
        },
      ],
      operates: {
        // 列操作按钮
        width: 200,
        fixed: "right",
        list: [],
      },
      total: 0,
      loading: true,
    });

    const init = async (params) => {
      state.loading = true;
      params.renewStatus = params.renewS;
      const { data } = await proxy.$api.maintenance.getLostRenewPage(params);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    const enterAddPage = (id, type, number) => {
      router.push({
        path: "/upkeepContract/renewUpkeepAdd",
        query: { id: id, type: type, number: number },
      });
    };

    return {
      ...toRefs(state),
      enterAddPage,
      init,
    };
  },
};
</script>
